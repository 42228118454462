import { useEffect, useState } from "react";
import { Typography, Grid, Box } from "@mui/material";
import "./SubCategoriesView.css";
import { useSelector } from "react-redux";
import {
  capitalizeSentence,
  getMenuItemName,
  getTimeInUAE,
  isCategoryAvailable,
} from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import { bucketURL } from "../../Constants/Enums";

const SubCategoriesView = ({ toggleView, onSelect, selectedMainCategory }) => {
  const { t, i18n } = useTranslation();

  const subCategories = useSelector((state) => state.subCategories.listing);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    prepareSubCategories();
  }, []); // list

  const prepareSubCategories = () => {
    setLoading(true);
    let newList = subCategories.filter((item) => {
      return (
        (item.guestView || item.guestOrder) &&
        isCategoryAvailable(item) &&
        selectedMainCategory.id === item.mainCategoryID
      );
    });
    setList(newList);
    setLoading(false);
  };

  return (
    <div>
      {
        <div
          className="Open"
          onClick={() => {
            toggleView();
          }}
        />
      }

      <div className="SubCategoriesView">
        <div className="SubCategoryContent">
          <Grid container spacing={"10px"}>
            {!loading &&
              list.map((item, index) => {
                return (
                  <Grid container item xs={12} key={item.id}>
                    <Grid
                      item
                      xs={12}
                      onClick={() => {
                        onSelect(item);
                      }}
                    >
                      <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        item
                        xs={12}
                        style={{
                          width: "100%",
                          minHeight: 80,
                          background:
                            "linear-gradient(135deg, white 0%, #ECE7DF 52%, #EAE6DD 56%, #FCF3DE 100%)",
                          borderRadius: 10,
                          border: "2px #D2B56F solid",
                        }}
                      >
                        {/* Category name column */}
                        <Grid
                          item
                          xs={item.image ? 10 : 12} // Adjust width if there's no image
                          container
                          direction="column"
                          justifyContent={"center"}
                          alignItems={"center"} // Centers text
                        >
                          <Typography
                            style={{
                              color: "black",
                              fontSize: 25,
                              fontFamily: `etihadBook${i18n.language}`,
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            {/* Conditionally render icon column */}
                            {item.image && (
                              <Box
                                component="img"
                                src={`${bucketURL}${item.image}`}
                                sx={{
                                  marginRight: "10px",
                                  width: "30px",
                                  height: "30px",
                                  transition: "opacity 400ms ease 0ms",
                                }}
                              />
                            )}
                            {getMenuItemName(item, i18n.language)}
                          </Typography>
                          <Typography
                            style={{
                              color: "black",
                              fontSize: 15,
                              fontFamily: `etihadBook${i18n.language}`,
                              fontWeight: "400",
                              wordWrap: "break-word",
                            }}
                          >
                            {capitalizeSentence(
                              new Map(JSON.parse(item.multiLanguages))?.get(
                                `${i18n.language}-${"subName"}`
                              ) ??
                                item.subName ??
                                ""
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default SubCategoriesView;
