import { useEffect, useState } from "react";
import "./Categories.css";
import { useTranslation } from "react-i18next";
import { getMenuItemName } from "../../helpers/utils";

function Categories({
  categories,
  onCategoryClick,
  main,
  selectedMainCategory,
  isSubCategory,
}) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setSelectedCategory(selectedMainCategory);
  }, [selectedMainCategory]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    onCategoryClick(category);

    const sectionElement = document.getElementById(category.name);
    if (sectionElement) {
      const topOffset = sectionElement.offsetTop;
      window.scrollTo({ top: topOffset - 130, behavior: "smooth" });
    }
  };

  return (
    <div className="CategoriesContainer">
      <div className="CategoriesWrapper">
        {categories?.map((category, index) => (
          <div
            key={category.id}
            className={`CategoryItem ${
              main &&
              selectedMainCategory.name === category.name &&
              !isSubCategory
                ? "selected"
                : isSubCategory
                ? selectedMainCategory.name === category.name
                  ? "selected__subCategory"
                  : ""
                : ""
            }`}
            onClick={() => handleCategoryClick(category)}
          >
            <div
              style={{
                color: "black",
                fontSize: 15,
                fontFamily: `etihadMedium${i18n.language}`,
                wordWrap: "break-word",
              }}
            >
              {getMenuItemName(category, i18n.language)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Categories;
