import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "./NavBar.css";
import { SideBarTabs } from "../../Constants/JsonObjects";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Languages from "../../Pages/Languages/Languages";
import { useTranslation } from "react-i18next";
import Dialog from "../Dialog/DialogAlert";
import Cookies from "js-cookie";
import { numberTranslation } from "../../helpers/utils";

const Navbar = ({ items }) => {
  const { t, i18n } = useTranslation();
  let concept = useSelector((state) => state.concept.listing);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentTarget, setCurrentTarget] = useState(false);

  const navigate = useNavigate();
  const table = useSelector((state) => state.table.listing);
  const canReview = JSON.parse(Cookies.get("userCookie")).canReview;

  const encryptedID = useSelector((state) => state.table.encryptedID);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleDialog = () => {
    setClicked(false);
  };
  return (
    <div>
      <AppBar position="static" style={{ boxShadow: "none", direction: "ltr" }}>
        <Toolbar className="NavBarContainer2">
          <IconButton
            edge="start"
            color="black"
            aria-label="menu"
            onClick={toggleDrawer}
            style={{ marginRight: "auto" }}
          >
            <MenuIcon style={{ color: "#AF8E4B" }} />
          </IconButton>
          <img
            alt="logo"
            src={require("../../assets/Etihad.png")}
            style={{
              width: 150,
              height: 100,
              borderRadius: 10,
              position: "fixed",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/${encryptedID}`)}
          />
          <div>
            {!concept.viewOnly && table && table.tableName && (
              <div
                style={{
                  maxWidth: "6rem",
                  width: "100%",
                  height: "100%",
                  textAlign: "end",
                  background: "#FCF9F2",
                  borderRadius: 15,
                  border: "1px #8C8585 solid",
                  padding: 2,
                  marginTop: 8,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    transformOrigin: "0 0",
                    textAlign: "center",
                    color: "#444441",
                    fontSize: 10,
                    fontFamily: `${i18n.language}"etihadBook"`,
                    fontWeight: "600",
                    wordWrap: "break-word",
                  }}
                >
                  {table &&
                    (new Map(JSON.parse(concept.multiLanguages))?.get(
                      `${i18n.language}-${"name"}`
                    ) ?? concept.name) +
                      "-" +
                      numberTranslation(table.tableName, i18n.language)}
                </div>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>

      {/* {items && (
        <NavExtension
          items={items}
          order={
            Cookies.get("trackedOrder") ? Cookies.get("trackedOrder") : null
          }
        />
      )} */}
      {clicked && !canReview && (
        <Dialog
          handleClose={toggleDialog}
          open={clicked}
          text={t(
            "You have already submitted your review for this item. Thank you for your feedback!"
          )}
          title={t("Already Reviewed")}
        />
      )}

      {isDrawerOpen && <div className="Overlay" onClick={toggleDrawer} />}

      <div
        className={`NavBarSidebar ${isDrawerOpen ? "open" : ""}`}
        style={{ direction: "ltr" }}
      >
        <List className="ListSideBar">
          <ListItem button className="UserInfo"></ListItem>
          {SideBarTabs.map((item) =>
            item.name !== "Reviews" && item.name !== "Languages" ? (
              <ListItem
                button
                key={item.name}
                component={Link}
                to={item.path === "home" ? `/${encryptedID}` : item.path}
              >
                <ListItemIcon style={{ color: "#AF8E4B" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  style={{ fontWeight: "bold" }}
                  primary={t(item.name)}
                />
              </ListItem>
            ) : item.name === "Languages" ? (
              <ListItem
                button
                key={item.name}
                component="div"
                onClick={(event) => {
                  setOpen(!open);
                  setCurrentTarget(event.currentTarget);
                }}
              >
                {open && (
                  <div>
                    <Languages type="icon" currentTarget={currentTarget} />
                  </div>
                )}
                <ListItemIcon style={{ color: "#AF8E4B" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={t(item.name)} />
              </ListItem>
            ) : (
              <ListItem
                button
                key={item.name}
                component="div"
                onClick={() => {
                  !canReview ? setClicked(true) : navigate(item.path);
                }}
              >
                <ListItemIcon style={{ color: "#AF8E4B" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={t(item.name)} />
              </ListItem>
            )
          )}
        </List>
        <div style={{ marginTop: "20rem" }}>
          <img
            alt="logo"
            src={require("../../assets/image-680.png")}
            style={{
              width: 150,
              height: 90,
              borderRadius: 10,
              cursor: "pointer",
            }}
          />
          <div
            style={{
              textAlign: "center",
              color: "rgba(2.39, 1.15, 1.15, 0.24)",
              paddingRight: ".5rem",
              fontSize: 12,
              fontFamily: "Montserrat",
              fontWeight: "500",
              wordWrap: "break-word",
            }}
          >
            {t("Copyright © 2023")}{" "}
          </div>
          <div
            style={{
              textAlign: "center",
              color: "rgba(2.39, 1.15, 1.15, 0.24)",
              paddingRight: ".5rem",
              fontSize: 12,
              fontFamily: "Montserrat",
              fontWeight: "500",
              wordWrap: "break-word",
            }}
          >
            {" "}
            {t("Capital Catering Services")}{" "}
          </div>
          <div
            style={{
              textAlign: "center",
              color: "rgba(2.39, 1.15, 1.15, 0.24)",
              paddingRight: ".5rem",
              fontSize: 12,
              fontFamily: "Montserrat",
              fontWeight: "500",
              wordWrap: "break-word",
            }}
          >
            {" "}
            {t("All rights reserved")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
