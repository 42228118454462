import "./Section.css";
import ItemCard from "../ItemCard/ItemCard";
import { useTranslation } from "react-i18next";
import "../../Pages/LanguageSelector/LanguageSelector";
import { getMenuItemName } from "../../helpers/utils";
import { useEffect, useState } from "react";

function Section({ Cart, handleOpenItem, category }) {
  const { t, i18n } = useTranslation();
  const [menuItems, setMenuItems] = useState(
    category.menuItems?.sort((a, b) => {
      return Number(a.precedence) - Number(b.precedence);
    })
  );

  return (
    <div className="SectionContainer" id={category.name}>
      <div className="SectionName">
        <div
          style={{
            color: "#AF8E4B",
            fontSize: 20,
            fontFamily: `${i18n.language}"etihadBook"`,
            fontWeight: "600",
            wordWrap: "break-word",
          }}
        >
          {getMenuItemName(category, i18n.language)}
        </div>
      </div>

      <div className="ItemsContainer">
        {menuItems.map((item, index) =>
          category.guestOrder ? (
            <ItemCard
              key={index}
              item={item}
              Cart={Cart}
              prepId={category.preparationAreaID}
              handleOpenItem={handleOpenItem}
            />
          ) : (
            <ItemCard
              key={index}
              item={item}
              Cart={Cart}
              prepId={category.preparationAreaID}
              canOrder={false}
              handleOpenItem={handleOpenItem}
            />
          )
        )}
      </div>
    </div>
  );
}

export default Section;
