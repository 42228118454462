import { useEffect, useState } from "react";
import "./MenuList.css";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Components/NavBar/NavBar";
import MainLoader from "../../Components/MainLoader/MainLoader";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import AllSections from "../../Components/AllSections/AllSections";
import Categories from "../../Components/Categories/Categories";
import { setSelectedCategory } from "../../store/ducks/app";
import { getMenuItemName, numberTranslation } from "../../helpers/utils";
import OrderDetails from "../../Pages/OrderDetails/OrderDetails";

function MenuList() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const originalCategoriesList = location.state.Categories;
  const [empty, setEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isItemOpen, setItemOpen] = useState(false);

  const [item, setitem] = useState(null);
  const [currentCart, setCurrentCart] = useState(null);
  const [canOrder, setCanOrder] = useState(null);
  const [prepId, setPrepId] = useState(null);

  const selectedSubCategory = useSelector(
    (state) => state.app.selectedSubCategory
  );

  const order = Cookies.get("trackedOrder")
    ? JSON.parse(Cookies.get("trackedOrder"))
    : null;

  const quantity = useSelector((state) => state.cart.quantity);
  const cart = useSelector((state) => state.cart.listing);
  const selectedCategory = useSelector((state) => state.app.selectedCategory);

  useEffect(() => {
    if (originalCategoriesList && originalCategoriesList.length > 0) {
      setFilteredCategories(originalCategoriesList);
    }
  }, []);

  const handleSearch = (searchValue) => {
    if (searchValue === "") {
      if (originalCategoriesList && originalCategoriesList.length > 0) {
        dispatch(setSelectedCategory(originalCategoriesList[0]));
        setFilteredCategories(originalCategoriesList);
      }
      setEmpty(false);
    } else {
      const filteredCategoryData = originalCategoriesList.map((category) => {
        const filteredMenuItems = category.menuItems.filter((item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        return {
          ...category,
          menuItems: filteredMenuItems,
        };
      });
      var filtered = filteredCategoryData.filter(
        (category) => category?.menuItems?.length > 0
      );
      //sort by precedence
      filtered = filtered.sort((a, b) => b.precedence - a.precedence);
      setFilteredCategories(filtered);
      setEmpty(filtered.length === 0);
    }
  };

  const handleCategoryClick = (subCategory) => {
    dispatch(setSelectedCategory(subCategory));
  };

  const handleViewBasket = () => {
    navigate("/cart", { state: { CartData: cart } });
  };
  const toggleView = () => {
    setItemOpen(!isItemOpen);
  };
  const handleOpenItem = (item, cart, canOrder, prepId) => {
    setitem(item);
    setCurrentCart(cart);
    setCanOrder(canOrder);
    setPrepId(prepId);
    setItemOpen(!isItemOpen);
  };

  const handleCloseItemCard = () => {
    setItemOpen(!isItemOpen);
  };
  return (
    <div className="Container">
      {isLoading ? (
        <div className="Container centerLoading">
          <MainLoader />
        </div>
      ) : (
        <>
          {!isItemOpen && (
            <div className="NavBarContainer">
              <Navbar items={[]} />
            </div>
          )}

          {isItemOpen && <div className="Overlay" onClick={toggleView} />}

          <div className={`item ${isItemOpen ? "open" : ""}`}>
            {isItemOpen ? (
              <OrderDetails
                handleCloseItemCard={handleCloseItemCard}
                item={item}
                cart={currentCart}
                canOrder={canOrder}
                prepId={prepId}
              ></OrderDetails>
            ) : (
              ""
            )}
          </div>

          <div className="FixedContainer">
            <div className="SearchContainer">
              <div className="image-container">
                <img
                  className="background-image"
                  alt="background"
                  src={require("../../assets/cover.png")}
                />
                <div
                  className="overlay-text"
                  style={{
                    color: "black",
                    fontSize: 18,
                    fontFamily: `etihadMedium${i18n.language}`,
                    wordWrap: "break-word",
                  }}
                >
                  {getMenuItemName(selectedSubCategory, i18n.language)}
                </div>
              </div>
              {/* <SearchBar onSearch={handleSearch} placeholder={"search"} /> */}
            </div>
          </div>

          {originalCategoriesList && (
            <div className="Categories" style={{ marginBottom: ".5rem" }}>
              <Categories
                categories={originalCategoriesList}
                onCategoryClick={handleCategoryClick}
                main={true}
                selectedMainCategory={selectedCategory}
                isSubCategory={true}
              />
            </div>
          )}

          {/* List Categories */}
          <AllSections
            categories={filteredCategories}
            Cart={cart}
            handleOpenItem={handleOpenItem}
          />

          {/* Cart Footer Card */}
          {cart &&
            cart?.orderedItems?.length >= 0 &&
            quantity !== 0 &&
            !isItemOpen && (
              <div className="CartDisplay" onClick={handleViewBasket}>
                <div className="CartDisplayInfo">
                  <Typography fontFamily={`etihadBold${i18n.language}`}>
                    <span
                      style={{
                        display: "inline-block",
                        height: "20px",
                        lineHeight: "20px",
                        textAlign: "center",
                        borderRadius: "30%",
                        border: "2px solid #fff",
                        padding: "3px",
                      }}
                    >
                      {numberTranslation(quantity, i18n.language)}
                    </span>{" "}
                  </Typography>
                  <Typography
                    fontSize={20}
                    fontFamily={`etihadBold${i18n.language}`}
                    width={"100%"}
                    textAlign={"center"}
                    justifyContent={"center"}
                  >
                    {t("basket display")}
                  </Typography>
                  {cart.totalPrice !== 0 && (
                    <Typography>
                      {t("total")} {cart.totalPrice}
                    </Typography>
                  )}
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default MenuList;
